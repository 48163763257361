<template>
  <app-dialog
    v-model="model"
    data-cy-post-registration-link-dialog
    prepend-image="/images/illustrations/post_registration_link.svg"
    subtitle="Vous allez désormais être redirigé.e vers votre espace Learner où vous retrouverez toutes les informations relatives à votre session Pollen."
    :title="title"
  >
    <template #footer>
      <app-button block color="dark" :href="redirectUrl" size="lg">
        Accéder à mon espace Learner
        <app-gauge-chart
          class="text-white"
          :max="8"
          :size="20"
          :stroke-width="3"
          :value="count"
        />
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
const model = defineModel({ default: false });

const properties = defineProps<{
  session: Pick<DatabaseTable<"course_sessions">, "id">;
}>();

const { me } = useEnrichedCurrentUser();

const title = computed(
  () => `Félicitations ${me.value!.first_name}, vous êtes inscrit.e !`,
);

const redirectUrl = computed(
  () =>
    `${window.location.origin}/auth/bridge/learner?redirect=/sessions/${properties.session.id}`,
);

const { count, dec } = useCounter(8, { min: 0 });

useIntervalFn(dec, 1000);

whenever(
  () => count.value === 0,
  () => {
    window.location.href = redirectUrl.value;
  },
);
</script>
